import React from 'react';
import { Text } from '../../helper/Typography';

export const CookieDescriptions = {
  requiredCookies: (locale: string) =>
    locale === 'de' ? (
      <>
        Diese Cookies sind aus technischen Gründen erforderlich, damit Sie
        unsere Website besuchen und die von uns angebotenen Funktionen nutzen
        können. Darüber hinaus tragen diese Cookies zu einer sicheren und
        ordnungsgemäßen Nutzung der Website bei.
      </>
    ) : (
      <>
        These cookies are necessary for technical reasons so that you can visit
        our website and use the functions we offer. In addition, these cookies
        contribute to the safe and proper use of the website.
      </>
    ),
  gaSnowPlow: (locale: string) =>
    locale === 'de' ? (
      <>
        <Text.pBold darkTheme={true}>Google Analytics</Text.pBold>
        <br />
        <Text.p darkTheme={true}>
          Diese Website benutzt Google Analytics incl. der Google
          Analytics-Werbefunktionen. Dabei handelt es sich um einen
          Webanalysedienst der Google Inc. („Google“). Google Analytics
          verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer
          gespeichert werden und die eine Analyse der Benutzung der Website
          durch Sie ermöglichen. <br /> <br /> Google Analytics wird
          ausschließlich mit aktivierter IP-Anonymisierung einsetzt (sog.
          IP-Masking). Dies bedeutet, dass die IP-Adresse der Nutzer von Google
          innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
          Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum
          gekürzt wird. Nur in Ausnahmefällen, wenn es z.B. zu technischen
          Ausfällen in Europa kommt, wird die volle IP-Adresse an einen Server
          von Google in den USA übertragen und dort gekürzt. <br /> <br />
          Bei der von Google verwendeten IP-Anonymisierungsmethode wird die
          vollständige IPAdresse zu keinem Zeitpunkt auf eine Festplatte
          geschrieben, da die gesamte Anonymisierung fast unmittelbar nach
          Erhalt der Anforderung im Arbeitsspeicher erfolgt. <br />
          <br />
          Die von dem Browser des Nutzers übermittelte IP-Adresse wird nicht mit
          anderen Daten von Google zusammengeführt. <br />
          <br />
          Im Auftrag des Betreibers dieser Website wird Google diese
          Informationen benutzen, um Ihre Nutzung der Website auf der Grundlage
          von Art. 6 Abs. 1 f DSGVO auszuwerten, um Reports über die
          Websiteaktivitäten zusammenzustellen und um weitere mit der
          Websitenutzung und der Internetnutzung verbundene Dienstleistungen,
          insbesondere auch Funktionen für Display-Werbung sowie Google
          Analytics-Berichte zur Leistung nach demografischen Merkmalen und
          Interessen gegenüber dem Websitebetreiber zu erbringen. <br /> <br />{' '}
          Auch wird Google diese Informationen gegebenenfalls an Dritte
          übertragen, sofern dies gesetzlich vorgeschrieben oder soweit Dritte
          diese Daten im Auftrag von Google verarbeiten. Hierbei wird es sich in
          keinem Fall um personenbezogene Daten handeln. <br /> <br /> In den
          Google Analytics-Berichten zur Leistung nach demografischen Merkmalen
          und Interessen werden über interessenbezogene Werbung von Google
          erlangte Daten und Besucherdaten von Drittanbietern (wie z.B.
          Altersgruppen, oder Interessensgruppen) verwendet. Sie können die
          Speicherung der Cookies durch eine entsprechende Einstellung Ihrer
          Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass
          Sie in diesem Fall gegebenenfalls nicht sämtliche Funktionen dieser
          Website vollumfänglich werden nutzen können. Sie können darüber hinaus
          die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der
          Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google sowie die
          Verarbeitung dieser Daten durch Google verhindern, indem sie das unter
          dem folgenden Link verfügbare Browser-Plugin herunterladen und
          installieren:{' '}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
          >
            Google Browser-Plugin herunterladen und installieren.
          </a>
          <br /> <br /> Nähere Informationen zu Nutzungsbedingungen und
          Datenschutz finden Sie{' '}
          <a
            href="https://www.google.de/analytics/terms/de.html"
            target="_blank"
          >
            hier
          </a>{' '}
          bzw.{' '}
          <a
            href="
                 https://www.google.de/intl/de/policies/"
            target="_blank"
          >
            hier.
          </a>{' '}
          Wir weisen Sie darauf hin, dass auf dieser Website Google Analytics um
          den Code „anonymizeIp“ erweitert wurde, um eine anonymisierte
          Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten. <br />{' '}
          <br />
        </Text.p>
      </>
    ) : (
      <>
        <Text.pBold darkTheme={true}>Google Analytics</Text.pBold>
        <br />
        <Text.p darkTheme={true}>
          This website uses Google Analytics including the Google Analytics
          advertising features. This is a web analysis service of Google Inc.
          ("Google"). Google Analytics uses so-called "cookies", text files that
          are stored on your computer and that allow an analysis of the use of
          the website by you. <br /> <br />
          Google Analytics is used exclusively with activated IP anonymization
          (so-called IP masking). This means that Google's IP address will be
          truncated within member states of the European Union or other parties
          to the Agreement on the European Economic Area. Only in exceptional
          cases, when e.g. technical breakdowns in Europe, the full IP address
          is transmitted toa Google server in the US and shortened there. <br />
          <br /> The IP anonymization method used by Google does not write the
          full IP address to disk at all times because all anonymization occurs
          almost immediately after receiving the request in memory. <br />
          <br /> The IP address submitted by the user's browser will not be
          merged with other data provided by Google. <br />
          <br />
          On behalf of the operator of this website, Google will use this
          information to evaluate your use of the website on the basis of Art. 6
          (1 f) GDPR, to compile reports on website activity and other services
          related to website activity and internet usage, in particular also
          provide display advertising and Google Analytics demographic and
          interest reporting services to the site operator. <br />
          <br />
          Google may also transfer this information to third parties if required
          by law or as far as third parties process this data on behalf of
          Google. This will in no case be personal data. <br /> <br /> The
          Google Analytics demographics and interest reports use third-party
          data and third-party visitor data (such as age groups or interest
          groups) obtained through Google's interest-based advertising.You may
          opt out of the use of cookies by selecting the appropriate settings on
          your browser, however please note that if you do this you may not be
          able to use the full functionality of this website.You can also
          prevent data generated by the cookie as well as data relating to your
          website usage (including your IP address) from being saved or
          processed by Google by downloading and installing the browser plug-in
          available at the following link: Download and install{' '}
          <a
            href="https://tools.google.com/dlpage/gaoptout?hl=en"
            target="_blank"
          >
            google browser-plugin.
          </a>{' '}
          <br /> <br />
          For more information on the terms of use and data protection go to{' '}
          <a
            href="https://www.google.de/analytics/terms/de.html"
            target="_blank"
          >
            Google Analytics
          </a>{' '}
          or{' '}
          <a href="https://www.google.de/intl/de/policies/" target="_blank">
            Google policies
          </a>
          . Please be advised that on this website, Google Analytics is
          supplemented with the code "anonymizelp" in order to guarantee that IP
          addresses are tracked in anonymised form (so-called IP masking).
        </Text.p>
      </>
    ),
};
